@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; 
@import "~quill/dist/quill.core.css";

body {
  margin: 0px !important;
  padding: 0px !important;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  box-sizing: border-box;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important;  /* IE 10+ */

  &::-webkit-scrollbar {
    display: none !important; /* Safari and Chrome */
  }
}

.voice-visualizer {
  display: flex; /* Arrange bars horizontally */
  align-items: flex-end; /* Align to bottom */
  width: 60px; /* Adjust width as needed */
}

.bar {
  width: 50px; /* Adjust width as needed */
  margin: 0 5px; /* Add spacing between bars */
  background-color: #2B4360; /* Initial color */
  transition: height 0.1s ease; /* Smooth height transition */
}

.p-paginator-page.p-highlight{
  background-color: #2B4360 !important;
  color: white;
  font-size: 14px;
  font-weight: 700;
}

.p-tabview-nav, .p-unselectable-text, .p-tabview-nav-link, .p-tabview-panels {
  background-color: transparent !important;
}

.p-tabview-nav-link{
  padding-bottom: 14px !important;
}

.p-highlight{
  color: #161616 !important;
}

.p-datatable-tbody > tr:not(:last-child) {
  border-bottom: 1px solid #EAECF0;
}

.p-datatable-thead > tr{
  border-bottom: 1px solid #EAECF0;
  background-color: transparent !important;
}

.p-paginator-pages > .p-highlight{
  color: white !important;
}
.controls {
  margin-top: 10px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.loader {
  width: 70px;
  height: 70px;
  display: flex;
}

.loader-circle {
  fill: none;
  stroke: #2B4360;
  stroke-width: 5;
  stroke-dasharray: 283;
  stroke-dashoffset: 283;
  animation: dash 2s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 283;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -283;
  }
}

@keyframes float {
  0%, 100% { transform: translate(50%, -50%); }
  50% { transform: translate(50%, -60%); }
}
.animate-float {
  animation: float 3s ease-in-out infinite;
}

/* testimonial section */
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.snap-x {
  scroll-snap-type: x mandatory;
}

.snap-center {
  scroll-snap-align: center;
}

.upload-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.upload-icon {
  font-size: 48px;
  color: #007bff;
  animation: bounce 1s infinite;
}

.upload-text {
  margin-top: 10px;
  font-size: 18px;
}

.progress-bar {
  width: 200px;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s ease;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.f-btn {
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 0.5s ease-in-out;
}

.f-btn::after {
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  content: "";
  background: #3C99CA;
  position: absolute;
  z-index: -1;
  padding: 0.85em 0.75em;
  display: block;
  border-radius: 50%;
  left: -50%;
  right: -50%;
  top: -150%;
  bottom: -150%;
  line-height: 8.34em;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
}

.f-btn:hover::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.f-btn:hover {
  color: #ffffff;
}

.p-inputnumber-input{
  max-width: 1.8rem !important;
  padding: 0px 3px !important;
}

.p-slider-handle{
  background-color: #3498db !important;
}

.line {
  fill: none;
  stroke: #3498db; /* Customize the border color */
  stroke-width: 8;
  stroke-dasharray: 150;
  stroke-dashoffset: 0;
  animation: dashAnimation 2s linear infinite;
}

@keyframes dashAnimation {
  0% {
      stroke-dashoffset: 150;
  }
  100% {
      stroke-dashoffset: 0;
  }
}


@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.95); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

.p-tabview-panels{
  padding: 0px !important;
}

.circular-progress {
  position: relative;
  width: 100px;
  height: 100px;
}

.circle-background {
  fill: none;
  stroke: #e0e0e0;
}

.circle-progress {
  fill: none;
  stroke: #3498db;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 1s ease-in-out;
}

.score {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
  font-weight: bold;
  animation: countUp 1s ease-out forwards;
}

@keyframes countUp {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

:root {
  --scrollbar-thumb-color: #3C99CA;
  --scrollbar-thumb-hover-color: #3C99CA;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-color) transparent;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}

.p-checkbox-box{
  border: solid 1px #2B4360;
}

.ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 40; 
  background: white;  
}

.markdown ul {
  list-style-type: disc; /* Ensure bullet points are shown */
  padding-left: 20px; /* Adjust padding for proper indentation */
}

.markdown li {
  margin-bottom: 8px; /* Add spacing between list items */
}

.markdown ul ul {
  list-style-type: circle; /* Different bullet style for nested lists */
}

.ql-snow .ql-code-block {
  background: #f5f5f5; /* Light grey background */
  border-left: 4px solid #ccc; /* Optional left border */
  padding: 10px; /* Padding for spacing */
  overflow: auto; /* Allow scrolling */
}

.ql-snow{
  .ql-picker{
      &.ql-size{
          .ql-picker-label,
          .ql-picker-item{
              &::before{
                  content: attr(data-value) !important;
              }
          }
      }
  }
}

.p-timeline-event-marker{
  border: 1px solid #3498db !important;
}